nightInit();

function nightInit() {
    nightmode();
    // switchDarkmode();
}

function switchDarkmode() {
    document.querySelector('[data-toggle="darkmode"]').addEventListener('click', function() {
        document.body.classList.toggle('night');
		document.body.classList.toggle('day');
    });
}

function nightmode() {
	var currentTime = new Date().getHours(),
		sunRise = 7,
		sunSet = 20;

	if (sunRise <= currentTime && currentTime < sunSet) {
		document.body.classList.remove('night');
		document.body.classList.add('day');
	} else {
		document.body.classList.remove('day');
		document.body.classList.add('night');
	}
}
