// document.querySelector('[data-toggle="menu"]').addEventListener('click', function() {
//     document.body.classList.toggle('active');
//     document.getElementById('menu').classList.toggle('active');
//     document.querySelector('[data-toggle="menu"]').classList.toggle('active');
// });

// document.addEventListener('click', function (event) {

//     // If the click happened inside the the container, bail
//     if (event.target.closest('#menu')) return;

//     // Otherwise, run our code...
//     // document.body.classList.remove('active');
//     // document.getElementById('menu').classList.remove('active');
//     // document.querySelector('[data-toggle="menu"]').classList.remove('active');
// }, false);
