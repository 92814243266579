var deadline = document.getElementById("contact-deadline");
if (deadline != null) {

    var today = new Date();
    var inSomeDays = 6 * 7; // 6 weeks * 7 days

    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var y = today.getFullYear();

    var inOneWeek = (dd + 7) + '/' + mm + '/' + y;
    var inOneYear = dd + '/' + mm + '/' + (y + 1);

    document.getElementById("contact-deadline").setAttribute("min", inOneWeek);
    document.getElementById("contact-deadline").setAttribute("max", inOneYear);

    today.setDate(today.getDate() + inSomeDays);

    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var y = today.getFullYear();

    var inFewDays = dd + '/' + mm + '/' + y;

    document.getElementById("contact-deadline").setAttribute("value", inFewDays);
    document.getElementById("contact-deadline").setAttribute("placeholder", inFewDays);

    // document.getElementById("contact-deadline").onchange = function () {
    //     var input = document.getElementById("secondDateId");
    //     input.setAttribute("min", this.value);
    // }
}
