var cursor = document.getElementById("_cursor");
var timeout;

window.addEventListener('mousemove', function (e) {
    var left = e.pageX + "px";
    var top = e.pageY + "px";
    cursor.style.top = top;
    cursor.style.left = left;
    cursor.classList.add('move');

    clearTimeout(timeout);
    timeout = setTimeout(mouseHasStopped, 300);
});

var can_hover = document.querySelectorAll('a, button, input');

for (i in can_hover) {
    can_hover[i].onmouseover = function () {
        cursor.classList.add('hover');
    }
    can_hover[i].onmouseout = function () {
        cursor.classList.remove('hover');
    }
}

function mouseHasStopped() {
    cursor.classList.remove('move');
}
