var slideInterval = 4000;

// document.getElementById('total-slide').innerHTML = document.getElementsByClassName('project').length;

function getFigures() {
    return document.getElementsByClassName('project');
}

function moveForward() {
    var pointer = 0;
    var figures = getFigures();

    document.getElementById('total-slide').value = figures.length;

    for (var i = 0; i < figures.length; i++) {
        if (figures[i].className == 'project card --active') {
            figures[i].className = 'project card --inactive';
            pointer = i;
        }
    }
    if (++pointer == figures.length) {
        pointer = 0;
    }
    figures[pointer].className = 'project card --active';
    document.getElementById('current-slide').innerHTML = ("0" + ++pointer).slice(-2);

    setTimeout(moveForward, slideInterval);
}

function startPlayback() {
    setTimeout(moveForward, slideInterval);
}

startPlayback();
